import { AxiosInstance } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { Cognito } from "../../utils/cognito";
import notification from "../../utils/notification";
import {
  watchCreateAgreement,
  watchDeleteAgreement,
  watchExportAgreements,
  watchLoadAgreements,
} from "../AgreementsPage/sagas";
import watchSearchCard from "../CardPage/sagas";
import {
  watchExportProcessesToExcel,
  watchLoadFuelingProcessDetails,
  watchLoadFuelingProcesses,
} from "../FuelingProcessesPage/sagas";
import watchLoadSessionProcesses from "../SessionLogin/sagas";
import {
  watchDownloadAllStationMarts,
  watchSaveStationMarts,
  watchUploadStationMarts,
} from "../StationMartsPage/sagas";
import {
  watchDownloadAllStations,
  watchSaveStations,
  watchUploadStations,
} from "../StationsPage/sagas";
import { ErrorAction, setError } from "./actions";
import { SET_ERROR } from "./constants";

export function* processErrorSaga(action: ErrorAction) {
  try {
    yield call(notification.showError, action.message);
  } catch (e) {
    yield call(
      console.error,
      `failed to notify about the error: ${action.message}`
    );
  }
}

export function* watchErrors() {
  try {
    yield takeLatest(SET_ERROR, processErrorSaga);
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* rootSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield all([
      watchLoadSessionProcesses(),
      watchLoadFuelingProcessDetails(axiosClient, cognito),
      watchSearchCard(axiosClient, cognito),
      watchLoadFuelingProcesses(axiosClient, cognito),
      watchExportProcessesToExcel(axiosClient, cognito),
      watchUploadStations(axiosClient, cognito),
      watchSaveStations(axiosClient, cognito),
      watchDownloadAllStations(axiosClient, cognito),
      watchLoadAgreements(axiosClient, cognito),
      watchCreateAgreement(axiosClient, cognito),
      watchDeleteAgreement(axiosClient, cognito),
      watchExportAgreements(axiosClient, cognito),
      watchUploadStationMarts(axiosClient, cognito),
      watchSaveStationMarts(axiosClient, cognito),
      watchDownloadAllStationMarts(axiosClient, cognito),
      watchErrors(),
    ]);
  } catch (error) {
    yield put(setError(error));
  }
}
