import { defineMessages } from "react-intl";

const scope = "supportui.components.import.StationsInitial";

export default defineMessages({
  upload: {
    id: `${scope}.upload`,
    defaultMessage: "Import",
  },
  export: {
    id: `${scope}.Export`,
    defaultMessage: "Export",
  },
  chooseOperation: {
    id: `${scope}.Choose operation`,
    defaultMessage: "Choose operation:",
  },
});
