export const ACTION_UPLOAD_STATIONS =
  "supportui/StationsPage/ACTION_UPLOAD_STATIONS";
export const ACTION_UPLOAD_STATIONS_SUCCEEDED =
  "supportui/StationsPage/ACTION_UPLOAD_STATIONS_SUCCEEDED";
export const ACTION_UPLOAD_STATIONS_FAILED =
  "supportui/StationsPage/ACTION_UPLOAD_STATIONS_FAILED";
export const ACTION_SAVE_STATIONS =
  "supportui/StationsPage/ACTION_SAVE_STATIONS";
export const ACTION_STATIONS_SAVED =
  "supportui/StationsPage/ACTION_STATIONS_SAVED";
export const ACTION_COMPLETE_STATIONS_UPLOAD =
  "supportui/StationsPage/ACTION_COMPLETE_STATIONS_UPLOAD";
export const ACTION_DOWNLOAD_STATIONS =
  "supportui/StationsPage/ACTION_DOWNLOAD_STATIONS";
export const ACTION_DOWNLOAD_STATIONS_STARTED =
  "supportui/StationsPage/ACTION_DOWNLOAD_STATIONS_STARTED";
