import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import AppContent from "../../components/AppContent";
import Downloading from "../../components/import-export/Downloading";
import Initial from "../../components/import-export/Initial";
import Saved from "../../components/import-export/Saved";
import Saving from "../../components/import-export/Saving";
import Uploading from "../../components/import-export/Uploading";
import ValidationFailure from "../../components/import-export/ValidationFailure";
import ValidationSuccess from "../../components/import-export/ValidationSuccess";
import State from "../../State";
import * as actions from "./actions";
import StationTable from "./components/StationTable";
import messages from "./messages";
import StationsPageState from "./state";

const StationsWrapper = styled.div`
  padding-top: 20px;
`;

const StationsLayout = styled(AppContent)`
  padding: 30px;
`;

function getContent(
  state: StationsPageState,
  uploadStations: (file: File) => void,
  completeStationsUpload: () => void,
  saveStations: (stationImportId: string) => void,
  downloadStations: () => void
): ReactNode {
  switch (state.type) {
    case "initial":
      return <Initial upload={uploadStations} download={downloadStations} />;
    case "saved":
      return (
        <Saved
          messages={{
            saved: messages.stationsSaved,
            return: messages.returnToStations,
          }}
          completeUpload={completeStationsUpload}
          totalCount={state.totalCount}
        />
      );
    case "saving":
      return (
        <Saving
          messages={{
            saving: messages.savingStations,
          }}
        />
      );
    case "uploading":
      return (
        <Uploading
          messages={{
            uploading: messages.uploadingStations,
          }}
        />
      );
    case "upload-succeeded":
      return (
        <ValidationSuccess
          messages={{
            toBeSaved: messages.stationsToBeSaved,
            returnTo: messages.returnToStations,
            save: messages.save,
          }}
          validatedData={<StationTable stations={state.stations} />}
          importId={state.stationImportId}
          return={completeStationsUpload}
          save={saveStations}
        />
      );
    case "upload-failed":
      return (
        <ValidationFailure
          messages={{
            uploadFailed: messages.stationsUploadFailed,
            errors: messages.errors,
            returnTo: messages.returnToStations,
          }}
          errors={state.errors}
          complete={completeStationsUpload}
        />
      );
    case "downloading":
      return <Downloading downloading={true} />;
  }
}

const StationsPage: React.FC = () => {
  const dispatch = useDispatch();

  const uploadStations = (file: File): void => {
    dispatch(actions.uploadStations(file as File));
  };

  const completeStationsUpload = (): void => {
    dispatch(actions.completeStationsUpload());
  };

  const saveStations = (stationImportId: string): void => {
    dispatch(actions.saveStations(stationImportId));
  };

  const downloadStations = (): void => {
    dispatch(actions.downloadStations());
  };

  const state = useSelector<State, StationsPageState>(
    (state) => state.stations
  );

  return (
    <StationsWrapper>
      <StationsLayout>
        {getContent(
          state,
          uploadStations,
          completeStationsUpload,
          saveStations,
          downloadStations
        )}
      </StationsLayout>
    </StationsWrapper>
  );
};

export default StationsPage;
