import { SET_ERROR } from "../App/constants";
import {
  ACTION_COMPLETE_STATIONS_UPLOAD,
  ACTION_DOWNLOAD_STATIONS,
  ACTION_DOWNLOAD_STATIONS_STARTED,
  ACTION_SAVE_STATIONS,
  ACTION_STATIONS_SAVED,
  ACTION_UPLOAD_STATIONS,
  ACTION_UPLOAD_STATIONS_FAILED,
  ACTION_UPLOAD_STATIONS_SUCCEEDED,
} from "./constants";
import StationsPageState from "./state";

const initialState: StationsPageState = {
  type: "initial",
};

const stationsReducer = (
  state: StationsPageState = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): StationsPageState => {
  switch (action.type) {
    case ACTION_UPLOAD_STATIONS:
      return {
        type: "uploading",
      };

    case ACTION_UPLOAD_STATIONS_SUCCEEDED:
      return {
        type: "upload-succeeded",
        stations: action.stations,
        stationImportId: action.stationImportId,
      };

    case ACTION_UPLOAD_STATIONS_FAILED:
      return {
        type: "upload-failed",
        errors: action.errors,
      };

    case ACTION_COMPLETE_STATIONS_UPLOAD:
      return {
        type: "initial",
      };

    case ACTION_SAVE_STATIONS:
      return {
        type: "saving",
        totalCount:
          state.type == "upload-succeeded" ? state.stations.length : 0,
      };

    case ACTION_STATIONS_SAVED:
      return {
        type: "saved",
        totalCount: state.type == "saving" ? state.totalCount : 0,
      };
    case ACTION_DOWNLOAD_STATIONS:
      return {
        type: "downloading",
      };
    case ACTION_DOWNLOAD_STATIONS_STARTED:
      return {
        type: "initial",
      };
    case SET_ERROR: {
      return {
        type: "initial",
      };
    }
    default:
      break;
  }

  return state;
};

export { initialState };
export default stationsReducer;
