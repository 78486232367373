import { combineReducers } from "redux";

import agreementTableReducer from "./containers/AgreementsPage/reducers";
import errorReducer from "./containers/App/reducers";
import cardReducer from "./containers/CardPage/reducers";
import {
  exportToExcelReducer,
  fuelingProcessesDetailsReducer,
  fuelingProcessesTableReducer,
} from "./containers/FuelingProcessesPage/reducers";
import sessionLoginReducer from "./containers/SessionLogin/reducer";
import stationMartsReducer from "./containers/StationMartsPage/reducers";
import stationsReducer from "./containers/StationsPage/reducers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    processesPage: combineReducers({
      detailState: fuelingProcessesDetailsReducer,
      tableState: fuelingProcessesTableReducer,
      exportToExcelState: exportToExcelReducer,
    }),
    card: cardReducer,
    session: sessionLoginReducer,
    stations: stationsReducer,
    agreements: agreementTableReducer,
    stationMarts: stationMartsReducer,
    error: errorReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
