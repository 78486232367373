import { defineMessages } from "react-intl";

const scope = "supportui.components.Stations";

export default defineMessages({
  errors: {
    id: `${scope}.errors`,
    defaultMessage: "Errors",
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: "Import",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save",
  },
  returnToStationMarts: {
    id: `${scope}.returnToStationMarts`,
    defaultMessage: "Return to station marts",
  },
  savingStationMarts: {
    id: `${scope}.savingStationMarts`,
    defaultMessage: "Saving station marts...",
  },
  uploadingStationMarts: {
    id: `${scope}.uploading`,
    defaultMessage: "Importing station marts...",
  },
  stationMartsUploadFailed: {
    id: `${scope}.stationMartsUploadFailed`,
    defaultMessage: "Station marts import failed",
  },
  stationMartsToBeSaved: {
    id: `${scope}.stationMartsToBeSaved`,
    defaultMessage: "Station marts to be saved",
  },
  stationMartsSaved: {
    id: `${scope}.stationMartsSaved`,
    defaultMessage: "{totalCount} station marts have been successfully saved",
  },
  stationId: {
    id: `${scope}.StationId`,
    defaultMessage: "StationId",
  },
  nextMartId: {
    id: `${scope}.NextMartId`,
    defaultMessage: "NextMartId",
  },
  totalCount: {
    id: `${scope}.TotalCount`,
    defaultMessage: "Total count: {count} item(s)",
  },
  export: {
    id: `${scope}.Export`,
    defaultMessage: "Export",
  },
  chooseOperation: {
    id: `${scope}.Choose operation`,
    defaultMessage: "Choose operation:",
  },
  fromDate: {
    id: `${scope}.FromDate`,
    defaultMessage: "FromDate",
  },
});
