import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Typography, Upload } from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";
import React from "react";

import { BottomIndentWrapper } from "../../../spaces";
import intl from "../../../utils/intl";
import messages from "./messages";
import ImportExportInitialProps from "./props";

const Initial: React.FC<ImportExportInitialProps> = (
  props: ImportExportInitialProps
) => {
  const uploadFile = (options: UploadRequestOption): void => {
    props.upload(options.file as File);
  };

  return (
    <>
      <Typography.Title level={5}>
        {intl.formatMessage(messages.chooseOperation)}
      </Typography.Title>
      <BottomIndentWrapper size="large">
        <Upload showUploadList={false} customRequest={uploadFile}>
          <Button type="primary" id="uploadButton" icon={<UploadOutlined />}>
            {intl.formatMessage(messages.upload)}
          </Button>
        </Upload>
        <Button
          id="downloadAllButton"
          type="primary"
          onClick={props.download}
          icon={<DownloadOutlined />}
        >
          {intl.formatMessage(messages.export)}
        </Button>
      </BottomIndentWrapper>
    </>
  );
};

export default Initial;
