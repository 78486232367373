import { Button, List, Typography } from "antd";
import React from "react";

import { BottomIndentWrapper, TopIndentWrapper } from "../../../spaces";
import intl from "../../../utils/intl";
import { ValidationFailureProps } from "./props";

const ValidationFailure: React.FC<ValidationFailureProps> = (
  props: ValidationFailureProps
) => {
  return (
    <>
      <div>
        <BottomIndentWrapper>
          <Typography.Title level={5}>
            {intl.formatMessage(props.messages.uploadFailed)}
          </Typography.Title>
        </BottomIndentWrapper>
      </div>
      <div>
        <List
          header={intl.formatMessage(props.messages.errors)}
          bordered
          dataSource={props.errors}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </div>
      <div>
        <TopIndentWrapper>
          <Button type="primary" onClick={props.complete}>
            {intl.formatMessage(props.messages.returnTo)}
          </Button>
        </TopIndentWrapper>
      </div>
    </>
  );
};

export default ValidationFailure;
